import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import PageBanner from "../components/PageBanner";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import SupportButtons from "../components/SupportButtons";

export const SupportersPageTemplate = ({
  description,
  communityPartners,
  donors,
  donationsMessage,
  plannedGivingMessage,
  scholarshipEndowmentMessage,
}) => {
  return (
    <>
      <PageBanner>
        <div className="width-container">
          <h1 className="page-banner-header">SCHOOL SUPPORTERS</h1>
          <p>{description}</p>
        </div>
      </PageBanner>
      <div className="layout-container">
        <div className="top-image-container bg-white alt-banner text-center">
          <h3>Event Sponsors</h3>
          <div className="grid-container partner-grid">
            {communityPartners.map((partner, index) => {
              return (
                <div key={`partner-${index}`} className="partner-grid-item">
                  <a
                    href={partner.linkURL}
                    title={partner.name}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={partner.name}
                  >
                    <PreviewCompatibleImage imageData={partner.image} />
                  </a>
                </div>
              );
            })}
          </div>
        </div>
        <section className="body-padding">
          <div>
            {donors.map(({ label = "", names = "" }, index) => {
              return (
                <div className="donor-section" key={`section-${index}`}>
                  <h5>{label}</h5>
                  <div className="grid-container">
                    {names.split(",").map((name, idx) => {
                      return (
                        <div
                          key={`name-${index}-${idx}`}
                          className="grid-item-donor"
                        >
                          <p>{name.trim()}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </section>
        <section className="body-padding">
          <SupportButtons
            plannedGivingMessage={plannedGivingMessage}
            donationsMessage={donationsMessage}
            scholarshipEndowmentMessage={scholarshipEndowmentMessage}
          />
        </section>
      </div>
    </>
  );
};

SupportersPageTemplate.propTypes = {
  description: PropTypes.string,
  communityPartners: PropTypes.array,
  donors: PropTypes.array,
};

const SupportersPage = ({ data }) => {
  const {
    markdownRemark: {
      frontmatter: {
        description,
        communityPartners,
        donors,
        donationsMessage,
        plannedGivingMessage,
        scholarshipEndowmentMessage,
      },
    },
  } = data;
  return (
    <Layout
      pageTitle={`School Supporters`}
      ogUrl="/supporters/"
      pageDecription="As a non-profit organization, Philips Academy welcomes and encourages others to engage in improving our community. Learn more about the people that help make Philips Academy possible."
    >
      <SupportersPageTemplate
        description={description}
        communityPartners={communityPartners}
        donors={donors}
        plannedGivingMessage={plannedGivingMessage}
        donationsMessage={donationsMessage}
        scholarshipEndowmentMessage={scholarshipEndowmentMessage}
      />
    </Layout>
  );
};

SupportersPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default SupportersPage;

export const supportersPageQuery = graphql`
  query SupportersPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        description
        communityPartners {
          name
          linkURL
          image {
            childImageSharp {
              fluid(maxWidth: 300, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        donors {
          label
          names
        }
        plannedGivingMessage
        donationsMessage
        scholarshipEndowmentMessage
      }
    }
  }
`;
